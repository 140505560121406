(function($) {
/*********************************************************************************************************/
/* -------------------------------------- Loader ------------------------------------------ */
/*********************************************************************************************************/
$(window).load(function() {
	//Loader
	$('#loader-container').delay(500).fadeOut(800);	
			
});

/*********************************************************************************************************/
/* -------------------------------------- Dropdown stopPropagation------------------------------------------ */
/*********************************************************************************************************/
$('.dropdown-menu').on('click', function (e) {
    e.stopPropagation();
});


/*********************************************************************************************************/
/* -------------------------------------- Left side menu trigger------------------------------------------ */
/*********************************************************************************************************/
var accordionsMenu = $('.cd-accordion-menu');
if (accordionsMenu.length > 0) {
    accordionsMenu.each(function () {
        var accordion = $(this);
        //detect change in the input[type="checkbox"] value
        accordion.on('change', 'input[type="checkbox"]', function () {
            var checkbox = $(this);
            (checkbox.prop('checked')) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
        });
    });
}


/*********************************************************************************************************/
/* -------------------------------------- Menu ------------------------------------------ */
/*********************************************************************************************************/
$('.nav-toggle li').on('click', function () {
    $(this).toggleClass('open');
    $('.left-side-nav, .page-content').toggleClass('push');
    $('body').toggleClass('left-side-nav-open');
});




})(jQuery);

